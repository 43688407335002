<template>
  <div class="app-main-content">
    <card>
      <template #tools>
        <div class="title">工时统计报表</div>
        <div class="right">
          <div class="item">
            <span class="label">人员</span>
            <a-select style="width: 250px;height: 32px;overflow: hidden;" allowClear :maxTagCount="2" mode="multiple"
                      show-search class="sort-select" placeholder="请选择" optionFilterProp="children"
                      v-model="selectOwners" @change="selectOwnersChange">
              <a-select-option :value="item.oid" v-for="item in ownersList" :key="item.oid">{{
                  item.title ? item.name + '-' + item.title : item.name
                }}
              </a-select-option>
            </a-select>
          </div>
          <div class="item">
            <span class="label">登记时间区间</span>
            <a-range-picker v-model="selectRange" format="YYYY-MM-DD" value-format="YYYY-MM-DD" @change="rangeChange" />
          </div>
          <div class="item">
            <span class="label">搜索</span>
            <a-input class="search-input" v-model="queryParams.project_name" placeholder="请输入项目名称">
              <a-icon slot="prefix" type="search" />
            </a-input>
          </div>
          <div class="item">
            <a-button class="search" @click="getWorktimeList">查询</a-button>
          </div>
        </div>
      </template>
      <div class="main">
        <div class="head d-flex j-c-space-b a-i-center">
          <div class="title">数据详情</div>
          <div class="right-info d-flex">
            <div>总计 {{ sum_hour }} 登记小时</div>
            <span class="line"></span>
            <div>总计 {{ list.length }} 条记录</div>
          </div>
        </div>
        <div class="table">
          <a-table
              v-if="list.length > 0"
              :data-source="list"
              rowKey="id"
              bordered
              defaultExpandAllRows
              :expandIcon="customExpandIcon"
              :pagination="false"
          >
            <a-table-column title="提交人" width="250px">
              <template v-slot:default="_, row">
                <div class="edp-title">
                  <a-avatar :size="16" :src="row.pic" />
                  <span>{{ row.creator_title ? row.creator_name + '-' + row.creator_title : row.creator_name }}</span>
                </div>
              </template>
            </a-table-column>
            <a-table-column title="登记时长" data-index="worktime" width="222px">
              <template v-slot:default="_, row">
                {{ row.worktime ? `${row.worktime}小时` : `` }}
              </template>
            </a-table-column>
            <a-table-column title="登记开始时间" data-index="start_date" width="331px"></a-table-column>
            <a-table-column title="项目名称" data-index="project_name" width="400px"></a-table-column>
            <a-table-column title="任务项名称" data-index="issue_name" width="401px"></a-table-column>
            <a-table-column title="状态" data-index="issue_status_name" width="193px">
              <template v-slot:default="_, row">
                <div v-if="row.issue_status_name"
                     :class="{'edp-status': true, blue: row.issue_status_name === '未开始',yellow: row.issue_status_name === '进行中',green: row.issue_status_name === '已完成',gray: row.issue_status_name === '已归档'}">
                  {{ row.issue_status_name }}
                </div>
                <div v-else>
                  合计登记{{ row.sum_worktime }}小时
                </div>
              </template>
            </a-table-column>
          </a-table>
        </div>
      </div>
    </card>
  </div>
</template>

<script>
import { fetch } from "@/utils/request";
import { genGuid } from "@/utils/helpFunc";
import { h } from "vue";
import lodash from "lodash";
import dayjs from "dayjs";

export default {
  data() {
    return {
      queryParams: {
        project_name: '',
        creator_oids: '',
        begin_time: '',
        end_time: ''
      },
      selectOwners: [],
      ownersList: [],
      selectRange: [],
      list: [],
      sum_hour: '0',
      sum_length: '0'
    }
  },
  created() {
    this.selectRange = [
      dayjs().startOf("month").format("YYYY-MM-DD"),
      dayjs().endOf("month").format("YYYY-MM-DD")
    ]
    this.getWorktimeList()
    this.getOwnersList()
  },
  methods: {
    customExpandIcon(props) {
      // 是否有子项，无子项不展示图标
      // record是每行的数据，后端返回的数据会有一个hasChild字段判断是project/list否有子项
      if (props.record && Array.isArray(props.record.children)) {
        // props.expanded a-table组件判断是否展开了为true是展开,false为没展开
        if (props.expanded) {
          // onClick事件必须添加上，相当于把a-table里的展开事件给了自定义的图标，不加的话点击会失效
          return h("i", {
            class: "iconfont icon-xiangxia expand-icon",
            on: { click: e => props.onExpand(props.record, e) }
          })
        } else {
          return h("i", {
            class: "iconfont icon-xiangxia expand-icon open",
            on: { click: e => props.onExpand(props.record, e) }
          })
        }
      } else {
        return h("span", { style: 'padding-left: 7px' })
      }
    },
    async getWorktimeList() {
      this.list = []
      this.queryParams.begin_time = this.selectRange[0] || ''
      this.queryParams.end_time = this.selectRange[1] || ''
      const res = await fetch("post", "/sta/worktime", this.queryParams)
      if (res.status === 0) {
        console.log(res);
        this.list = res.data.map(e => {
          return {
            ...e,
            id: genGuid(32),
            sum_worktime: Number(e.sum_worktime),
            children: e.children.map(e1 => {
              return {
                ...e1,
                id: genGuid(32),
                creator_name: e.creator_name,
                creator_title: e.creator_title,
                pic: e.pic
              }
            })
          }
        })

        this.sum_hour = lodash.ceil(lodash.sumBy(this.list, 'sum_worktime'), 2)
      }

    },
    // 获取负责人数据
    async getOwnersList() {
      const res = await fetch("post", "/cpyaccount/retrieve", { page_size: 999 })
      if (res.status === 0) {
        this.ownersList = res.data.datas
      }
    },
    selectOwnersChange(e) {
      this.queryParams.creator_oids = e.join(',')

      this.getWorktimeList()
    },
    rangeChange() {
      this.getWorktimeList()
    }
  }
}
</script>

<style lang="less" scoped>
.app-main-content {
  /deep/ .edp-card {
    .edp-card-head {
      display: none;
    }

    .edp-card-tools {
      padding: 30px;
      min-height: 32px;

      .title {
        font-weight: 500;
        font-size: 22px;
        color: #4D4D4D;
      }
    }

    .edp-card-body {
      .main {
        padding: 0 30px;

        .head {
          margin-bottom: 30px;

          .title {
            font-size: 22px;
            font-weight: 500;
            color: #4D4D4D;
          }

          .right-info {

            > .line {
              line-height: 27px;
              height: 27px;
              width: 1px;
              background-color: #CACACA;
              margin: 0 15px;
            }

            > div {
              line-height: 27px;
            }
          }
        }

        .table {
          .ant-table {
            .ant-table-thead {
              > tr {
                > th {
                  font-weight: 700;
                  font-size: 18px;
                  line-height: 18px;
                  height: 18px;
                  color: #4D4D4D;
                  padding: 16px;
                  border-color: transparent;
                  background: #f8f8f8;

                  &:nth-last-child(1) {
                    border-color: #EDEDED;
                  }
                }
              }
            }

            .ant-table-tbody {
              tr {
                td {
                  font-weight: 400;
                  height: 16px;
                  line-height: 16px;
                  font-size: 16px;
                  color: #4D4D4D;
                  padding: 16px;
                  border-right-color: transparent;

                  .edp-title {
                    font-size: 16px;

                    span {
                      display: inline-block;
                      margin-right: 5px;
                      vertical-align: middle;
                      height: 16px;
                      font-size: 16px;
                    }
                  }

                  .edp-owners {
                    display: inline-block;
                  }

                  &:nth-last-child(1) {
                    border-right-color: #EDEDED;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
